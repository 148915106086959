import * as React from "react"

export function DisableUserZoom() {
    React.useEffect(() => {
        const updateViewport = () => {
            let viewport = document.querySelector("meta[name=viewport]")
            if (!viewport) {
                viewport = document.createElement("meta")
                viewport.name = "viewport"
                document.head.appendChild(viewport)
            }
            viewport.content =
                "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        }

        updateViewport()

        // 監聽 viewport 變化，防止被覆蓋
        const observer = new MutationObserver(updateViewport)
        observer.observe(document.head, { childList: true, subtree: true })

        return () => observer.disconnect()
    }, [])

    return null
}
